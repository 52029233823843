import ChatMessage from "../ChatMessage/ChatMessage";
import {CircularProgress} from "@mui/material";
import React from "react";

function ChatMessagesList({ messages, chatLanguage, proofread, proofreadModel, onAssistantMessageClick, selectedMsgId }) {
    return (
        <div style={{
            flex: 1,
            padding: '20px',
            overflowY: 'auto',
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd'
        }}>
            {
                messages.map((msg, index) => {
                    const prevMessageContent = index > 0 ? messages[index - 1].content : "None"
                    console.log(`prevMessageContent: ${prevMessageContent}`)
                    if (proofread && msg.role === "user") {
                        return (
                            <div className="hover-container" key={index}>
                                <ChatMessage
                                    username={msg.username}
                                    content={msg.content}
                                    prevMessageContent={prevMessageContent}
                                    targetLanguage={chatLanguage}
                                    sourceLanguage="English"
                                    proofread={proofread}
                                    isUser={true}
                                    proofreadModel={proofreadModel} />
                            </div>
                        )
                    } else {
                        return (
                            <ChatMessage
                                key={index}
                                username={msg.username}
                                content={msg.content}
                                prevMessageContent={prevMessageContent}
                                audioUrl={msg.audioUrl}
                                targetLanguage={chatLanguage}
                                sourceLanguage="English"
                                proofread={proofread}
                                isUser={msg.role === "user"}
                                proofreadModel={proofreadModel}
                                onMessageClick={() => onAssistantMessageClick(msg.audioUrl, index)}
                                msgId={index}
                                selectedMsgId={selectedMsgId}
                                hidden={msg.hidden}
                            />
                        )
                    }
                })
            }
        </div>
    );
}

export default ChatMessagesList;