import {updateUserChatSettings} from "./updateUserChatSettings";
const handleLanguageChangeWithState = (setChatLanguageName, setChatLanguageCode, setChatLanguageRegionCode, setMessages, getAccessTokenSilently) => {
    const handleLanguageChange = (selectedLangName, languages, defaultRegCode, userId, difficultyLevel) => {
        console.log(`handleLanguageChange triggered with ${selectedLangName} and languages`, languages)
        const selectedLangCode = languages[selectedLangName].code
        let defaultRegionCode = defaultRegCode || null
        if (Object.keys(languages[selectedLangName].regions).length !==0 ){
            defaultRegionCode = languages[selectedLangName].regions[Object.keys(languages[selectedLangName].regions)[0]]
        }
        setChatLanguageName(selectedLangName);
        setChatLanguageCode(selectedLangCode);
        setChatLanguageRegionCode(defaultRegionCode)
        setMessages([])
        updateUserChatSettings(userId, getAccessTokenSilently, selectedLangName, selectedLangCode, defaultRegionCode, difficultyLevel)
    }
    return handleLanguageChange;
};

export default handleLanguageChangeWithState;