import {
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormGroup,
    InputLabel
} from '@mui/material';
import LanguageProficiencyQuestion from '../LanguageProficiency/LanguageProficiencyQuestion'

function RenderInput({ type, question, options, answer, onChange, inputIndex }) {
    switch (type) {
        case 'freetext':
            return (
                <TextField
                    variant="outlined"
                    fullWidth
                    label={question}
                    value={answer}
                    onChange={(e) => {onChange(inputIndex, e.target.value)}}
                    style={{"marginBottom": "20px"}}
                />
            );
        case 'single_select':
            return (
                <FormGroup>
                <Typography variant="subtitle1">{question}</Typography>
                <FormControl fullWidth variant="outlined" margin="normal">
                    {/*<InputLabel shrink >*/}
                    {/*    {question}*/}
                    {/*</InputLabel>*/}
                    <Select value={answer} onChange={(e) => onChange(inputIndex, e.target.value)} >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                </FormGroup>
            );
        case 'multi_select':
            return (
                <FormGroup>
                    <Typography variant="subtitle1">{question}</Typography>
                    {options.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={answer.includes(option)}
                                    onChange={() => {
                                        const newAnswers = answer.includes(option)
                                            ? answer.filter(ans => ans !== option)
                                            : [...answer, option];
                                        onChange(inputIndex, newAnswers);
                                    }}
                                />
                            }
                            label={option}
                        />
                    ))}
                </FormGroup>
            );

        default:
            return null;
    }
}

function QuestionAnswer({ questionData, answer, onChange, onEnterPress }) {

    if (!questionData || !questionData.input_type || !questionData.question) {
        return <div>Error: Invalid question data!</div>;
    }

    if (questionData.input_type.includes("multi_select") && questionData.input_type.includes("single_select")) {
        return (
            <LanguageProficiencyQuestion
                languages={questionData.select_options[0]}
                proficiencies={questionData.select_options[1]}
                onChange={onChange}
            />
        );
    }

    return (
        <div>
            {questionData.input_type.map((type, index) => (
                <RenderInput
                    key={index}
                    type={type}
                    question={questionData.question[index]}
                    options={questionData.select_options ? questionData.select_options[index] : []}
                    answer={answer[index]}
                    onChange={onChange}
                    inputIndex={index}
                />
            ))}
        </div>
    );
}

export default QuestionAnswer;
