import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import packageInfo from '../package.json';
const version = packageInfo.version;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Auth0Provider
        domain="auth.fluidspeak.com"
        clientId="DMitF0PTq37wQFvIJEWt8UB2sPCU8YeT"
        authorizationParams={{
            redirect_uri: window.location.origin
        }}
        scope="openid profile email"
    >
        <App />
    </Auth0Provider>
  // </React.StrictMode>
);
console.log(`App Version: ${version}`)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
