import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Button } from '@mui/material';
import ChatMessagesList from "../ChatMessagesList/ChatMessagesList";
import MessageInput from "../MessageInput/MessageInput";
import SettingsDrawer from "../SettingsDrawer/SettingsDrawer";
import ChatHeader from "./ChatHeader";
import './Chat.css'
import { useAuth0 } from "@auth0/auth0-react";
import useOpenAI from "../../hooks/useOpenAI";
import useAssistantMessage from "./hooks/useAssistantMessage";
import useAudioRecording from "./hooks/useAudioRecording";
import useHandleSendMessageWithState from "./handlers/handleSendMessageWithState";
import handleLanguageChangeWithState from "./handlers/handleLanguageChangeWithState";
import handleLanguageRegionChangeWithState from "./handlers/handleLanguageRegionChangeWithState";
import fetchValidLanguagesWithState from "./handlers/fetchValidLanguagesWithState";
import useHandleProofreadChangeWithState from "./handlers/handleProofreadChangeWithState";
import useToggleDrawerWithState from "./handlers/toggleDrawerWithState";
import useHandleToggleHideMessages from "./hooks/useHandleToggleHideMessages";
import {updateUserChatSettings} from "./handlers/updateUserChatSettings";


const ChatApp = ({userAppData, onLogout}) => {
    const [userName, setUsername] = useState(userAppData.first_name);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [difficulty, setDifficulty] = useState("Intermediate")
    const [chatLanguageName, setChatLanguageName] = useState(null);
    const [chatLanguageCode, setChatLanguageCode] = useState("es");
    const [chatLanguageRegionCode, setChatLanguageRegionCode] = useState("US")
    const [messages, setMessages] = useState([]);
    const [sessionId, setSessionId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [proofread, setProofread] = useState(true);
    const [openAiModel, setOpenAiModel] = useState("gpt-4")
    const [proofreadModel, setProofreadModel] = useState("gpt-4")
    const [messagesAreHidden, setMessagesAreHidden] = useState(false);
    const [languages, setLanguages] = useState({});
    const mediaRecorderRef = useRef(null);
    const botName = "Tutor"
    const { getAccessTokenSilently } = useAuth0();
    const [queryOpenAI] = useOpenAI(setMessages, setIsLoading, getAccessTokenSilently, chatLanguageCode, chatLanguageRegionCode, openAiModel);
    const {audioSrc, setAudioSrc, selectedMessageId, setSelectedMessageId, handleAssistantMessageClick} = useAssistantMessage();
    const handleSendMessage = useHandleSendMessageWithState(userName, messages, setMessages, setIsLoading, queryOpenAI, botName, messagesAreHidden, setAudioSrc, setSelectedMessageId);
    const [currentUserMessage, setCurrentUserMessage] = useState("");
    const {isRecording, setIsRecording, sendingRecording, startRecording, stopRecording} = useAudioRecording(chatLanguageCode, setCurrentUserMessage);

    const handleLanguageChange = handleLanguageChangeWithState(setChatLanguageName, setChatLanguageCode, setChatLanguageRegionCode, setMessages, getAccessTokenSilently);
    const handleLanguageRegionChange = handleLanguageRegionChangeWithState(setChatLanguageRegionCode, setMessages, getAccessTokenSilently);
    const toggleDrawer = useToggleDrawerWithState(drawerOpen, setDrawerOpen);
    const handleProofreadChange = useHandleProofreadChangeWithState(proofread, setProofread);
    const handleToggleHideMessages = useHandleToggleHideMessages(messagesAreHidden, setMessagesAreHidden, setMessages);


    console.log("ChatApp rendered");
    console.log("user", userAppData)


    useEffect( () => {
        const updatedName = userAppData.first_name
        if (updatedName !== userName){
            setUsername(updatedName)
        }
        if(!chatLanguageName && Object.keys(languages).length !== 0 && userAppData.target_languages && userAppData.target_languages.length > 0){
            // console.log("log languages inside conditional", languages)
            if(userAppData.chat_settings && userAppData.chat_settings.chat_language){
                const selectedLangName = userAppData.chat_settings.chat_language
                setChatLanguageName(selectedLangName);
                setChatLanguageCode(languages[selectedLangName].code);
                let defaultRegionCode = userAppData.chat_settings.language_region || null
                if (!defaultRegionCode && Object.keys(languages[selectedLangName].regions).length !==0 ){
                    defaultRegionCode = languages[selectedLangName].regions[Object.keys(languages[selectedLangName].regions)[0]]
                }
                setChatLanguageRegionCode(defaultRegionCode);
                setDifficulty(userAppData.chat_settings.difficulty_level)
            } else {
                handleLanguageChange(userAppData.target_languages[0].language, languages, null, userAppData.id, difficulty)
            }

        }
    }, [userAppData, languages]);

    useEffect(() => {
        // Create an async function inside the useEffect
        const fetchInitialMessage = async () => {
            try {
                const openAiResponse = await queryOpenAI([], userAppData.id); // Await the response
                if (openAiResponse && openAiResponse.message) {
                    console.log("OpenAiResponse response", openAiResponse)
                    const newAssistantMessage = {
                        role: "assistant",
                        username: botName,
                        content: openAiResponse.message,
                        audioUrl: openAiResponse.audioUrl,
                        hidden: messagesAreHidden
                    }
                    setSessionId(openAiResponse.sessionId)
                    setMessages(prevMessages => [...prevMessages, newAssistantMessage]);
                    setAudioSrc(openAiResponse.audioUrl)
                }
            } catch (error) {
                console.error("Error fetching initial message", error);
                // TODO: handle error gracefully
            }
        };

        // Call the async function
        if(chatLanguageName){
            fetchInitialMessage();
        }

        const fetchValidLanguages = fetchValidLanguagesWithState(setLanguages);
        fetchValidLanguages();

        if (typeof mediaRecorderRef === 'undefined') {
            alert('Your browser does not support the mediaRecorder API. Please use a different browser or enjoy text message version of our app.');
            return;
        }

    }, [chatLanguageName, chatLanguageRegionCode]);


    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>

            {/* Header with user's name and settings button */}
            <ChatHeader userName={userName} chatLanguageName={chatLanguageName} toggleDrawer={toggleDrawer}/>

            <ChatMessagesList
                messages={messages}
                chatLanguage={chatLanguageName}
                proofread={proofread}
                proofreadModel={proofreadModel}
                onAssistantMessageClick={handleAssistantMessageClick}
                selectedMsgId={selectedMessageId}
            />
            {audioSrc && (
                <audio controls autoPlay style={{width: '100%', marginTop: '10px'}} key={audioSrc}>
                    <source src={audioSrc} type="audio/mp3"/>
                    Your browser does not support the audio element.
                </audio>
            )}
            {isLoading && <div style={{textAlign: 'center', marginTop: '10px'}}><CircularProgress/></div>}

            {/* Text input field for sending messages */}
            <MessageInput style={{borderTop: '1px solid #ddd', padding: '10px', display: 'flex', alignItems: 'center'}}
                          currentUserMessage={currentUserMessage}
                          setCurrentUserMessage={setCurrentUserMessage}
                          onSendMessage={(currentUserMsg) => {handleSendMessage(currentUserMsg, userAppData.id, sessionId)}}
                          areMessagesHidden={messagesAreHidden}
                          handleToggleHideMessages={handleToggleHideMessages}
                          isRecording={isRecording}
                          setIsRecording={setIsRecording}
                          sendingRecording={sendingRecording}
                          startRecording={startRecording}
                          stopRecording={stopRecording}
            />
            {chatLanguageName && <SettingsDrawer open={drawerOpen}
                            onClose={toggleDrawer}
                            difficulty={difficulty}
                            languageCode={chatLanguageCode}
                            languageRegionCode={chatLanguageRegionCode}
                            languageName={chatLanguageName}
                            openAiModel={openAiModel}
                            proofread={proofread}
                            proofreadModel={proofreadModel}
                            onDifficultyChange={(event) => {
                                const newDifficulty = event.target.value
                                updateUserChatSettings(userAppData.id, getAccessTokenSilently, chatLanguageName, chatLanguageCode, chatLanguageRegionCode, newDifficulty)
                                setDifficulty(newDifficulty)

                            }}
                            onLanguageChange={(langName) => {
                                handleLanguageChange(langName, languages, null, userAppData.id, difficulty)
                            }}
                            onLanguageRegionChange={(event) => {
                                handleLanguageRegionChange(event.target.value, userAppData.id, chatLanguageName, chatLanguageCode, difficulty)
                            }}
                            onProofreadChange={handleProofreadChange}
                            onLogout={() => {onLogout()}}
                            onOpenAiModelChange={(event) => {setOpenAiModel(event.target.value)}}
                            onProofreadModelChange={(event) => {setProofreadModel(event.target.value)}}
                            languages={languages}
                            user={userAppData}
            />}
        </div>
    );
};

export default ChatApp;
