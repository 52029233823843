// hooks/useAudioRecording.js
import { useState, useRef } from 'react';

const useAudioRecording = (chatLanguageCode, setCurrentUserMessage) => {
    const [isRecording, setIsRecording] = useState(false);
    const [sendingRecording, setIsSendingRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);

    const handleDataAvailable = (event) => {
        if (event.data.size > 0) {
            recordedChunksRef.current.push(event.data);
        }
    }

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = handleDataAvailable;
                mediaRecorderRef.current.onerror = (event) => {
                    console.error("mediaRecorderRef.current Error:", event.error);
                };

                mediaRecorderRef.current.onstart = () => {
                    console.log("mediaRecorderRef.current started");

                    // stop recording after 30 ms
                    setTimeout(() => {
                        if (mediaRecorderRef.current.state === "recording") {
                            stopRecording();
                        }
                    }, 30000); // 30000 milliseconds = 30 seconds
                };

                mediaRecorderRef.current.onstop = () => {
                    console.log("mediaRecorderRef.current stopped");
                };
                mediaRecorderRef.current.start();
            })
            .catch(err => {
                console.error("Microphone access was denied or there was another issue:", err);
                alert("Failed to access the microphone. Please allow access and try again.");
            });


    }

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.onstop = async () => {
                const audioBlob = new Blob(recordedChunksRef.current, {'type': 'audio/webm'});
                const formData = new FormData();
                formData.append('user_audio_file', audioBlob, "recording.webm");
                formData.append('target_lang_code', chatLanguageCode);
                formData.append('source_lang_code', 'en-US');

                setIsSendingRecording(true)
                try {
                    const response = await fetch(`${process.env.REACT_APP_CHAT_BACKEND_HOST}/audio_chat`, {
                        method: 'POST',
                        body: formData
                    });

                    const data = await response.json();
                    setCurrentUserMessage(data.message);
                } catch (error) {
                    console.log("error sending recording", error)
                    mediaRecorderRef.current.stop();
                    recordedChunksRef.current = [];
                    setIsRecording(false)
                } finally {
                    setIsSendingRecording(false);
                }

                // Clear recorded chunks after processing
                recordedChunksRef.current = [];
            };

            mediaRecorderRef.current.stop();
        } else {
            console.error("mediaRecorderRef.current is not in a state that allows stopping.");
        }
    };

    return {
        isRecording,
        setIsRecording,
        sendingRecording,
        startRecording,
        stopRecording
    };
}

export default useAudioRecording;
