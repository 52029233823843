import {useEffect, useState} from 'react';
import { FormControl, Select, MenuItem, IconButton, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function LanguageProficiencyQuestion({ languages, proficiencies, onChange }) {
    const defaultLang = languages.length > 0 ? languages[0] : ""
    // console.log("languages", languages)
    // console.log("default lang", defaultLang)
    const [selectedLanguages, setSelectedLanguages] = useState([{ language: "", proficiency: proficiencies[0] }]);

    const handleLanguageChange = (index, language) => {
        const newLanguages = [...selectedLanguages];
        newLanguages[index].language = language;
        console.log('Languages in LanguageProficiencyQuestion in handleLanguageChange:', newLanguages);
        setSelectedLanguages(newLanguages);
        onChange(newLanguages);
    };

    useEffect(() => {
        handleLanguageChange(0, defaultLang)
    }, []);

    const handleProficiencyChange = (index, proficiency) => {
        const newLanguages = [...selectedLanguages];
        newLanguages[index].proficiency = proficiency;
        console.log('Languages in LanguageProficiencyQuestion in handleProficicencyChange:', newLanguages);
        setSelectedLanguages(newLanguages);
        onChange(newLanguages);
    };

    const addLanguageRow = () => {
        console.log('Languages in LanguageProficiencyQuestion in addLanguageRow:', selectedLanguages);
        setSelectedLanguages([...selectedLanguages, { language: '', proficiency: proficiencies[0] }]);
    };

    const removeLanguageRow = (index) => {
        const newLanguages = [...selectedLanguages];
        newLanguages.splice(index, 1);
        setSelectedLanguages(newLanguages);
        onChange(newLanguages);
    };

    return (
        <div>
            <Typography variant="subtitle1" style={{"marginBottom": "20px"}}>Which languages are you learning?</Typography>
            <Grid container item xs={12} spacing={2}>
            {selectedLanguages.map((langObj, index) => (
                <Grid container item xs={12} spacing={2} key={index}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Select
                                value={langObj.language}
                                onChange={(e) => handleLanguageChange(index, e.target.value)}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    Select a language
                                </MenuItem>
                                {languages.map(language => (
                                    <MenuItem key={language} value={language}>
                                        {language}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Select
                                value={langObj.proficiency}
                                onChange={(e) => handleProficiencyChange(index, e.target.value)}
                            >
                                {proficiencies.map(prof => (
                                    <MenuItem key={prof} value={prof}>
                                        {prof}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        {index !== 0 && (
                            <IconButton onClick={() => removeLanguageRow(index)}>
                                <RemoveIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={addLanguageRow}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            </Grid>
        </div>
    );
}

export default LanguageProficiencyQuestion;
