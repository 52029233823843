import React, {useEffect, useState} from "react";
import ChatApp from "../Chat/Chat";
import Login from "../../Login";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import Onboarding from "../Onboarding/Onboarding";
import {fetchOnboardingQuestions, fetchUserAppDataWithState, postAddUserWithState, onboardingAnswersToPayload} from "./handlers";

function LoadingScreen() {
    return <div>Loading... <CircularProgress/></div>;
}

const App = () => {
    const { isAuthenticated, user, isLoading, logout } = useAuth0();
    const [userAppData, setUserAppData] = useState(null);
    const [loginErrorMsg, setLoginErrorMsg] = useState(null);
    const [isOnboarding, setIsOnboarding] = useState(false);
    const [questions, setQuestions] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    const handleOnboardingSubmit = async (answers) => {
        // submit onboarding answers to backend server
        console.log('onboardingAnswersToPayload',JSON.stringify(onboardingAnswersToPayload(answers, user.email), null, 2))
        const postAddUser = postAddUserWithState(getAccessTokenSilently)
        await postAddUser(answers, user.email);
        // console.log("Submitted onboarding answers:", answers)
        const fetchUserAppData = fetchUserAppDataWithState(setUserAppData, getAccessTokenSilently)
        await fetchUserAppData(user.email);
    }
    console.log()
    useEffect(() => {
        console.log("user", user)
        if (user && user.email_verified) {
            // const metadata = user["https://fluidspeak.com/user_metadata"];
            // user.metadata = metadata
            console.log("userAppData", userAppData)
            if(!userAppData){
                console.log("fetching user app data")
                const fetchUserAppData = fetchUserAppDataWithState(setUserAppData, getAccessTokenSilently)
                fetchUserAppData(user.email);
            } else if(userAppData === "400") {
                // TODO: onboard user, need to post new user data
                const getOnboardingQs = fetchOnboardingQuestions(setQuestions, getAccessTokenSilently);
                getOnboardingQs();
                setIsOnboarding(true);
            } else{
                setIsOnboarding(false);
                if(loginErrorMsg && !loginErrorMsg.startsWith("Please verify")){
                    setLoginErrorMsg(null);
                }
            }

        } else if (user && !user.email_verified) {
            setLoginErrorMsg(`Please verify the email sent to ${user.email} to continue.`)
        }

    }, [user, userAppData, isOnboarding]);

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: window.location.origin } })
    }
    if (isLoading) {
        return <LoadingScreen/>
    }

    return <div>
        {console.log(`env url: ${process.env.REACT_APP_CHAT_BACKEND_HOST}`)}
        {console.log("isOnboarding", isOnboarding)}
        {console.log("loginErrorMsg", loginErrorMsg)}
        {console.log("App.js user", user)}
        {console.log("App.js userAppData", userAppData)}
        {/* TODO: deal with tons of re-renders */}
        {user && isAuthenticated && !loginErrorMsg
            ? ( isOnboarding
                    ? (questions ? <Onboarding questions={questions} onSubmit={handleOnboardingSubmit} onLogout={handleLogout} userEmail={user.email}/> : <LoadingScreen/>)
                    : (userAppData) ? <ChatApp userAppData={userAppData} onLogout={handleLogout} /> : <LoadingScreen/>
            )
            : <Login loginErrorMsg={loginErrorMsg}/>}
    </div>

}

export default App;