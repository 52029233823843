import React, {useState, useEffect} from "react";
import {CircularProgress} from '@mui/material'
import '../ChatMessage/ChatMessage.css'
import {useAuth0} from "@auth0/auth0-react";

const UserChatMessage = ({ username, content, prevMessageContent, targetLanguage, sourceLanguage, proofread, proofreadModel}) => {
    const [feedback, setFeedback] = useState("");
    const [translation, setTranslation] = useState("");
    const [correction, setCorrection] = useState("");
    const [isProofread, setIsProofread] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hoverPosition, setHoverPosition] = useState("below");
    const [isHovered, setIsHovered] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleMouseEnter = (event) => {
        if (event.clientY > window.innerHeight/2) {
            setHoverPosition('above');
        } else {
            setHoverPosition('below');
        }
        setIsHovered(true);
    }

    const handleMouseLeave = () => {
        setIsHovered(false); // Set hover to false when mouse leaves
    }

    useEffect(() => {
        if(proofread){
            const fetchProofread = async () => {
                const payload = {
                    messages: [{
                        role: "assistant",
                        content: prevMessageContent,
                    }, {
                        role: "user",
                        content: content
                    }],
                    target_language: targetLanguage,
                    source_language: sourceLanguage,
                    model: proofreadModel
                };
                try {
                    setIsLoading(true);
                    const token = await getAccessTokenSilently({
                        scope: "openid profile email"
                    });
                    const response = await fetch(`${process.env.REACT_APP_CHAT_BACKEND_HOST}/proofread`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(payload)
                    });
                    setIsLoading(false)
                    const data = await response.json();
                    console.log(`UserChatMessage fetch response data ${JSON.stringify(data)}`)
                    if (response.ok){
                        setFeedback(data.feedback);
                        setTranslation(data.translation)
                        setCorrection(data.correction);
                        setIsProofread(true);
                    }
                } catch (error) {
                    console.log(`Error during proofreading`, error)
                }
            };
            fetchProofread();
        }
    }, [content, proofread, targetLanguage, sourceLanguage]);

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className="hover-container">
                <strong>{username}:</strong>&nbsp;
                {
                    isProofread
                        ? (correction === "None"
                                ? <span style={{color: 'green'}}> {content}</span>
                                : <span style={isHovered ? {color: 'green'} : {color: 'red'}}> {isHovered ? correction : content}</span>
                        )
                        : content
                }
                {isLoading && <div style={{display: 'inline', verticalAlign:'middle'}}>&nbsp;<CircularProgress size={"1em"} /></div>}
                <div className={`hover-content-${hoverPosition}`} style={{display: isHovered && isProofread ? 'block' : 'none'}}>
                    {/*<b>Correction:</b> {correction} <br/><br/>*/}
                    <b>Translation:</b> "{translation}" <br/><br/>
                    <b>Feedback:</b> {feedback} <br/><br/>
                </div>
            </div>

        </div>
    );
};

export default UserChatMessage;