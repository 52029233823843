import {useEffect, useRef} from "react";

function useHandleSendMessageWithState(userName, messages, setMessages, setIsLoading, queryOpenAI, botName, messagesAreHidden, setAudioSrc, setSelectedMessageId) {
    const messagesRef = useRef(messages);
    const userNameRef = useRef(userName);
    const queryOpenAIRef = useRef(queryOpenAI);
    const botNameRef = useRef(botName);
    const  messagesAreHiddenRef = useRef(messagesAreHidden);
    useEffect(() => {
        messagesRef.current = messages;
        userNameRef.current = userName;
        queryOpenAIRef.current = queryOpenAI;
        botNameRef.current = botName;
        messagesAreHiddenRef.current = messagesAreHidden;

    }, [messages, userName, queryOpenAI, botName, messagesAreHidden]);
    const handleSendMessage = async (message, userId, sessionId) => {
        const newUserMessage = {role: "user", username: userNameRef.current, content: message}
        const updatedMessages = [...messagesRef.current, newUserMessage];
        setMessages(updatedMessages);

        setIsLoading(true);  // Set loading to true
        const openAiResponse = await queryOpenAIRef.current(updatedMessages, userId, sessionId);
        setIsLoading(false);  // Set loading to true
        if (openAiResponse && openAiResponse.message) {
            const newAssistantMessage = {
                role: "assistant",
                username: botNameRef.current,
                content: openAiResponse.message,
                audioUrl: openAiResponse.audioUrl,
                hidden: messagesAreHiddenRef.current
            }
            setMessages([...updatedMessages, newAssistantMessage]);
            setAudioSrc(openAiResponse.audioUrl);
            setSelectedMessageId(updatedMessages.length) // index of new Assistant message
            console.log(`setting audio source to ${openAiResponse.audioUrl}`)
        }
    }
    return handleSendMessage;
};

export default useHandleSendMessageWithState;