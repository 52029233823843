import React from 'react';
import { Paper, Typography, Container } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LoginButton from './LoginButton';

const Login = ({loginErrorMsg}) => {
    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src="/static/original_logo.png" alt="Your Logo" style={{ width: '500px', marginBottom: '20px' }} />
                <LoginButton loginErrorMsg={loginErrorMsg} />

                {loginErrorMsg && (
                    <div style={{ marginTop: '20px', border: '1px solid red', borderRadius: '5px', padding: '10px', backgroundColor: '#ffe6e6' }}>
                        <Typography variant="body1" color="error" style={{ display: 'flex', alignItems: 'center' }}>
                            <ErrorOutlineIcon style={{ marginRight: '8px' }} />
                            {loginErrorMsg}
                        </Typography>
                        <Typography variant="body2" style={{ marginTop: '10px' }}>
                            Contact <a href="mailto:support@fluidspeak.com">support@fluidspeak.com</a> if you need another verification email.
                        </Typography>
                    </div>
                )}
            </Paper>
        </Container>
    )
}

export default Login;
