import {useState} from "react";

function useAssistantMessage() {
    const [audioSrc, setAudioSrc] = useState(null);
    const [selectedMessageId, setSelectedMessageId] = useState(0);

    const handleAssistantMessageClick = (audioUrl, messageId) => {
        if(audioUrl) {
            setAudioSrc(audioUrl);
            setSelectedMessageId(messageId);
        }
    };

    return {
        audioSrc, setAudioSrc, selectedMessageId, setSelectedMessageId, handleAssistantMessageClick
    };
}

export default useAssistantMessage;