import React from 'react';
import {
    Container,
    Button,
    Box,
    Stepper,
    Step,
    StepLabel,
    Typography,
    AppBar,
    Toolbar,
    IconButton
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Import the logout icon
import QuestionAnswer from './QuestionAnswer/QuestionAnswer';
import useQuestionNavigation from './hooks/useQuestionNavigation';

function Onboarding({ questions, onSubmit, onLogout, userEmail }) {
    const {
        currentQuestionIndex,
        answers,
        handleAnswerChange,
        handleNext,
        handlePrev,
    } = useQuestionNavigation(questions);

    function isAnswered(questionType, answer) {
        console.log("isanswered called with", answer)
        switch (questionType) {
            case 'freetext':
                return answer.trim() !== '';
            case 'single_select':
                return answer !== '';
            case 'multi_select':
                return answer.length > 0;
            default:
                return true;  // Assuming other types are always considered answered for now
        }
    }

    function areAllRequiredQuestionsAnswered(question, answers) {
        if(!question.required){
            return true;
        }
        if (question.input_type.includes("multi_select") && question.input_type.includes("single_select")) {
            return true;
        }
        return question.required.every((isRequired, index) => {
            if (isRequired) {
                return isAnswered(question.input_type[index], answers[index]);
            }
            return true;  // If the question isn't required, always consider it answered
        });
    }


    const areCurrentQuestionsAnswered = areAllRequiredQuestionsAnswered(questions[currentQuestionIndex], answers[currentQuestionIndex]);

    return (
        <Container maxWidth="md">
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar >
                        <Box flexGrow={1}></Box>
                        <Typography variant="subtitle1" >
                            {userEmail}
                        </Typography>
                        <IconButton color="inherit" onClick={onLogout}>
                            <ExitToAppIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            <Box display="flex" justifyContent="center" flexGrow={1}>
                <img src="/static/transparent_logo.png" alt="FluidSpeak" style={{ width: '100vw', maxWidth: '400px', height: 'auto' }} />

            </Box>
                <Box display="flex" justifyContent="center" mt={2}>
                    <Typography variant="subtitle2" style={{"marginBottom": "20px"}}>Let's answer a few questions to get started.</Typography>
                </Box>
            <Box mt={0}>
                <Stepper activeStep={currentQuestionIndex} alternativeLabel>
                    {questions.map((_, index) => (
                        <Step key={index}>
                            <StepLabel></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <Box my={5}>
                <QuestionAnswer
                    questionData={questions[currentQuestionIndex]}
                    answer={answers[currentQuestionIndex]}
                    onChange={handleAnswerChange}
                    onEnterPress={handleNext}
                />
            </Box>

            <Box display="flex" justifyContent="space-between" mt={3}>
                <Button
                    variant="outlined"
                    onClick={handlePrev}
                    disabled={currentQuestionIndex === 0}
                >
                    Previous
                </Button>

                {currentQuestionIndex === questions.length - 1 ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onSubmit(answers);
                        }}
                    >
                        Submit
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={handleNext} disabled={!areCurrentQuestionsAnswered}>
                        Next
                    </Button>
                )}
            </Box>
        </Container>
    );
}

export default Onboarding;
