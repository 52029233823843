import {useEffect, useRef} from "react";

const useOpenAI = (setMessages, setIsLoading, getAccessTokenSilently, chatLanguageCode, chatLanguageRegionCode, openAiModel) => {
    const openAiModelRef = useRef(openAiModel);
    const chatLanguageCodeRef = useRef(chatLanguageCode);
    const chatLanguageRegionCodeRef = useRef(chatLanguageRegionCode);
    useEffect(() => {
        openAiModelRef.current = openAiModel;
        chatLanguageCodeRef.current = chatLanguageCode;
        chatLanguageRegionCodeRef.current = chatLanguageRegionCode;

    }, [openAiModel, chatLanguageCode, chatLanguageRegionCode]);

    function base64ToArrayBuffer(base64) {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    const queryOpenAI = async (messages, userId, sessionId) => {
        setIsLoading(true);  // Indicate that loading has started
        const token = await getAccessTokenSilently();
        const payload = {
            messages: messages.slice(-5).map(m => ({ role: m.role, content: m.content })),
            language_code: chatLanguageRegionCode ? `${chatLanguageCodeRef.current}-${chatLanguageRegionCodeRef.current}` : chatLanguageCodeRef.current,
            model: openAiModelRef.current,
            synthesize: true,
            session_id: sessionId,
            user_id: userId
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_CHAT_BACKEND_HOST}/openai`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            setIsLoading(false);  // Reset the loading state

            if (data.audio) {
                const audioBlob = new Blob([new Uint8Array(base64ToArrayBuffer(data.audio))], { type: "audio/mp3" });
                const audioUrl = URL.createObjectURL(audioBlob);
                return {
                    message: data.message,
                    sessionId: data.session_id,
                    audioUrl: audioUrl
                };
            } else {
                return {
                    sessionId: data.session_id,
                    message: data.message
                };
            }

        } catch (error) {
            setIsLoading(false);  // Reset the loading state in case of error
            console.error('Error sending message to backend server', error);
        }
    }

    return [queryOpenAI];
}

export default useOpenAI;