import {useEffect, useRef} from "react";

const useHandleToggleHideMessages = (messagesAreHidden, setMessagesAreHidden, setMessages) => {
    const messagesAreHiddenRef = useRef(messagesAreHidden);
    useEffect(() => {
        messagesAreHiddenRef.current = messagesAreHidden
    }, [messagesAreHidden]);
    const handleToggleHideMessages = () => {
        const shouldHide = !messagesAreHiddenRef.current
        setMessagesAreHidden(shouldHide);
        setMessages(prevMessages => {
            return prevMessages.map(msg => {
                if (msg.role === "assistant") {
                    return {
                        ...msg,
                        hidden: shouldHide ? true : false
                    };
                }
                return msg;
            });
        });
    };

    return handleToggleHideMessages;
};
export default useHandleToggleHideMessages;