export const fetchOnboardingQuestions = (setQuestions, getAccessTokenSilently) => {
    const fetchOnboardingQuestions = async () => {
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${process.env.REACT_APP_CHAT_BACKEND_HOST}/onboarding_questions`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            })
            const data = await response.json()
            setQuestions(data);
            // console.log(`onboarding_questions response: ${JSON.stringify(data)}`)
        } catch (error) {
            console.error('Error fetching onboarding question', error)
        }
    };
    return fetchOnboardingQuestions;

};

export const fetchUserAppDataWithState = (setUserAppData, getAccessTokenSilently) => {
    const fetchUserAppData = async (userEmail) => {
        try {
            const token = await getAccessTokenSilently();
            const baseUrl = `${process.env.REACT_APP_CHAT_BACKEND_HOST}/get_user`;
            const params = {
                user_email: userEmail
            }
            const queryString = new URLSearchParams(params).toString();
            const urlWithParams = `${baseUrl}?${queryString}`;
            const response = await fetch(urlWithParams, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            // console.log("fetchUserAppData response", response)
            if (response.status == 400) {
                setUserAppData("400")
            }
            else if (response.status == 200) {
                const data = await response.json()
                // console.log("fetchUserAppData response data", data)
                setUserAppData(data);
            }
            else {
                throw new Error(`Unexpected response status fetching userAppData: ${response.status}`);
            }
            // console.log(`onboarding_questions response: ${JSON.stringify(data)}`)
        } catch (error) {
            console.error('Error fetching onboarding question', error)
        }
    };
    return fetchUserAppData;
}

export function onboardingAnswersToPayload(answers, email) {
    // TODO: make more modular, depend only on what we get from server instead of hardcoded answers
    return {
        email: email,
        first_name: answers[0][0],
        last_name: answers[0][1],
        usage_category: answers[1][0],
        native_language: answers[3][0],
        motivations: answers[4][0],
        target_languages: answers[2]
    };
}
export const postAddUserWithState = (getAccessTokenSilently) => {
    const postAddUser = async (answers, email)=> {
        const token = await getAccessTokenSilently();
        const baseUrl = `${process.env.REACT_APP_CHAT_BACKEND_HOST}/add_user`;
        const payload = onboardingAnswersToPayload(answers, email)
        fetch(baseUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    return postAddUser;
}