import {AppBar, Toolbar, Typography, IconButton} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
const ChatHeader = ({userName, chatLanguageName, toggleDrawer}) => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{flexGrow: 1}}>
                    Welcome, {userName}
                </Typography>
                <Typography variant="subtitle1" style={{ marginRight: '20px' }}>
                    Currently Learning: <b>{chatLanguageName}</b>
                </Typography>
                <IconButton edge="end" color="inherit" onClick={toggleDrawer}>
                    <MenuIcon/>
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

export default ChatHeader;