import {updateUserChatSettings} from "./updateUserChatSettings";
const handleLanguageRegionChangeWithState = (setChatLanguageRegionCode, setMessages, getAccessTokenSilently) => {
    const handleLanguageRegionChange = (chatLanguageRegionCode, userId, chatLangName, chatLangCode, difficultyLevel) => {
        console.log(`handleLanguageRegionChange triggered with ${JSON.stringify(chatLanguageRegionCode)}`)
        setChatLanguageRegionCode(chatLanguageRegionCode)
        setMessages([]);
        updateUserChatSettings(userId, getAccessTokenSilently, chatLangName, chatLangCode, chatLanguageRegionCode, difficultyLevel)
    };
    return handleLanguageRegionChange;
};

export default handleLanguageRegionChangeWithState;