import {useEffect, useRef} from "react";

const useHandleProofreadChangeWithState = (proofread, setProofread) => {
    const proofreadRef = useRef(proofread);
    useEffect(() => {
        proofreadRef.current = proofread;
    }, [proofread]);
    const handleProofreadChange = () => {
        setProofread(!proofreadRef.current)
    };
    return handleProofreadChange;
};
export default useHandleProofreadChangeWithState;