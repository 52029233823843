const fetchValidLanguagesWithState = (setLanguages) => {
    const fetchValidLanguages = async () => {
        try {

            const response = await fetch(`${process.env.REACT_APP_CHAT_BACKEND_HOST}/languages`)
            const data = await response.json()
            setLanguages(data);
            // console.log(`languages response: ${JSON.stringify(data)}`)
        } catch (error) {
            console.error('Error fetching languages', error)
        }
    };
    return fetchValidLanguages;

};
export default fetchValidLanguagesWithState;