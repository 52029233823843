import {useEffect, useRef} from "react";

const useToggleDrawerWithState = (drawerOpen, setDrawerOpen) => {
    const drawerOpenRef = useRef(drawerOpen);
    useEffect(() => {
        drawerOpenRef.current = drawerOpen;
    }, [drawerOpen]);
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpenRef.current);
    };
    return toggleDrawer;
};
export default useToggleDrawerWithState;