export const updateUserChatSettings = async (userId, getAccessTokenSilently, chatLanguage, languageCode, languageRegion, difficultyLevel) => {
        try {
            const token = await getAccessTokenSilently();
            const payload = {
                user_id: userId,
                chat_language: chatLanguage,
                language_code: languageCode,
                language_region: languageRegion,
                difficulty_level: difficultyLevel
            }
            const _response = await fetch(`${process.env.REACT_APP_CHAT_BACKEND_HOST}/update_user_chat_settings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            })
            // console.log(`languages response: ${JSON.stringify(data)}`)
        } catch (error) {
            console.error('Error updating user chat settings', error)
        }
    };
