import React, {useRef, useState} from "react";
import {Button, TextField, CircularProgress} from "@mui/material";

function MessageInput({
    currentUserMessage,
    setCurrentUserMessage,
    onSendMessage,
    areMessagesHidden,
    handleToggleHideMessages,
    isRecording,
    setIsRecording,
    sendingRecording,
    startRecording,
    stopRecording
}) {
    const textFieldInputRef = useRef(null);

    const handleSendMessage = () => {
        if (currentUserMessage.trim() !== "") {
            onSendMessage(currentUserMessage);
            setCurrentUserMessage("");
        }
    };

    const handleRecording = async () => {
        if (isRecording) {
            await stopRecording();
            setIsRecording(false);
            if(textFieldInputRef.current){
                textFieldInputRef.current.focus();
            }
        } else {
            startRecording();
            setIsRecording(true);
        }
    };

    return (
        <div style={{ borderTop: '1px solid #ddd', padding: '10px', display: 'flex', alignItems: 'center' }}>
            <TextField
                ref={textFieldInputRef}
                fullWidth
                variant="outlined"
                value={currentUserMessage}
                onChange={(e) => setCurrentUserMessage(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                    }
                }}
                placeholder="Type a message"
            />
            <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                color="primary"
                onClick={handleSendMessage}>
                Send
            </Button>
            <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                color="secondary"
                onClick={handleToggleHideMessages}>
                {areMessagesHidden ? "Show Messages" : "Listening Mode"}
            </Button>
            {sendingRecording ?
                <CircularProgress style={{ marginLeft: '10px' }}/>
                :
                <Button
                    style={{ marginLeft: '10px' }}
                    variant="contained"
                    color="primary"
                    onClick={handleRecording}>
                    {isRecording ? "Stop Recording" : "Start Recording"}
                </Button>
            }
        </div>
    );
}

export default MessageInput;