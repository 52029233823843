import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LoginButton = ({loginErrorMsg}) => {
    const { loginWithRedirect, logout} = useAuth0();

    const handleButtonClick = () => {
        if(loginErrorMsg) {
            logout({ logoutParams: { returnTo: window.location.origin } });
        } else {
            loginWithRedirect();
        }
    }

    return (
        <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleButtonClick}
            style={{ width: '100%', padding: '10px', fontSize: '16px' }}
        >
            {loginErrorMsg ? 'Logout' : 'Get Started'}
        </Button>
    );
};

export default LoginButton;
