// useOnboarding.js
import { useEffect, useRef, useState } from 'react';

function useQuestionNavigation(questions) {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState(initializeAnswers(questions));
    const questionsRef = useRef(questions);

    function initializeAnswers(questions) {
        return questions.map((question) => {
            return question.input_type.map((type, index) => {
                switch (type) {
                    case 'freetext':
                        return '';
                    case 'single_select':
                        return question.select_options[index] ? question.select_options[index][0] || '' : '';
                    case 'multi_select':
                        return [];
                    default:
                        return null;
                }
            });
        });
    }


    useEffect(() => {
        questionsRef.current = questions;
    }, [questions]);

    const handleAnswerChange = (...args) => {
        const updatedAnswers = [...answers];

        if (args.length === 1) {
            // Only the answer is provided (e.g., from LanguageProficiencyQuestion)
            updatedAnswers[currentQuestionIndex] = args[0];
        } else {
            // Both inputIndex and newAnswer are provided
            const [inputIndex, newAnswer] = args;
            if (Array.isArray(updatedAnswers[currentQuestionIndex])) {
                updatedAnswers[currentQuestionIndex][inputIndex] = newAnswer;
            } else {
                updatedAnswers[currentQuestionIndex] = newAnswer;
            }
        }

        setAnswers(updatedAnswers);
    };




    const handleNext = () => {
        if (currentQuestionIndex < questionsRef.current.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    return {
        currentQuestionIndex,
        answers,
        handleAnswerChange,
        handleNext,
        handlePrev,
    };
}

export default useQuestionNavigation;
