import React, {useState, useEffect} from "react";
import {CircularProgress} from '@mui/material'
import '../ChatMessage/ChatMessage.css'
import VolumeUp from '@mui/icons-material/VolumeUp';
import {useAuth0} from "@auth0/auth0-react";

const AssistantChatMessage = ({ username, content, prevMessageContent, targetLanguage, sourceLanguage, explain, proofreadModel, isSelected, onMessageClick, hidden}) => {
    const [isExplain, setIsExplain] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hoverPosition, setHoverPosition] = useState("below");
    const [isHovered, setIsHovered] = useState(false);
    const [explanation, setExplanation] = useState("");
    const [translation, setTranslation] = useState("");
    const [fetchTriggered, setFetchTriggered] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        setIsExplain(explain)
        setIsLoading(true);
        // setFetchTriggered(true);
        const dummyFetchExplanation = async () => {
            await fetchExplanation();
            setIsLoading(false);
        }
        dummyFetchExplanation();
    }, [explain]);

    const handleMouseEnter = async (event) => {
        if (event.clientY > window.innerHeight / 2) {
            setHoverPosition('above');
        } else {
            setHoverPosition('below');
        }
        setIsHovered(true);
        // TODO: validate that loading automatically is the right move
        // if(!fetchTriggered){
        //     setIsLoading(true);
        //     setFetchTriggered(true);
        //     await fetchExplanation();
        //     setIsLoading(false);
        // }

    }

    const handleMouseLeave = () => {
        setIsHovered(false); // Set hover to false when mouse leaves
    }

    const fetchExplanation = async () => {
        const token = getAccessTokenSilently();
        const payload = {
            messages: [{
                role: "user",
                content: prevMessageContent
            },{
                    role: "assistant",
                    content: content
                }],
            target_language: targetLanguage,
            source_language: sourceLanguage,
            model: proofreadModel
        }
        try {
            console.log("Sending payload:", payload);
            const response = await fetch(`${process.env.REACT_APP_CHAT_BACKEND_HOST}/explain`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            console.log(`AssistantChatMessage fetch response data ${JSON.stringify(data)}`)
            if (response.ok) {
                setExplanation(data.explanation);
                setTranslation(data.translation);
            }
        } catch (error) {
            console.log("Error fetching explanation and translation", error)
        }
    }

    const superscriptSpanStyle = explanation
        ? (explanation === "There was an error retrieving the explanation"
            ? { color: 'red' }
            : { color: 'green' })
        : {};

    return (
        <div onClick={onMessageClick}>
            {isSelected && <VolumeUp style={{ verticalAlign: 'middle', marginRight: '5px', color: 'blue'}} />}
            <strong>{username}:</strong>&nbsp;
            {hidden ? "<Hidden>" : content}
            {isExplain && <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <span className="hover-container">
                    <span className="explanation-superscript" style={superscriptSpanStyle}>
                    &nbsp;[?]
                    </span>
                    <div className={`hover-content-${hoverPosition}`} style={{ display: isHovered && isExplain ? 'block' : 'none' }}>
                    {isLoading ?
                        <span>Loading explanation... <CircularProgress size={"1em"} /></span>
                        :
                        <>
                            <b>Translation:</b> "{translation}" <br/><br/>
                            <b>Explanation:</b> "{explanation}" <br/><br/>
                        </>
                    }

                </div>
                </span>
            </span>
            }
            {isLoading && <div style={{display: 'inline', verticalAlign:'middle'}}>&nbsp;<CircularProgress size={"1em"} /></div>}
        </div>
    )
}

export default AssistantChatMessage;