import React, {useState, useEffect} from "react";
import './ChatMessage.css'
import AssistantChatMessage from "../AssistantChatMessage/AssistantChatMessage";
import UserChatMessage from "../UserChatMessage/UserChatMessage";

const ChatMessage = ({ msgId, username, content, prevMessageContent, audioUrl, targetLanguage, sourceLanguage, proofread, isUser, proofreadModel, onMessageClick, selectedMsgId, hidden}) => {
    return (
        <div>
            {
                isUser
                ? <UserChatMessage username={username} content={content} prevMessageContent={prevMessageContent} targetLanguage={targetLanguage} sourceLanguage={sourceLanguage} proofread={proofread} proofreadModel={proofreadModel}/>
                : <AssistantChatMessage username={username} content={content} prevMessageContent={prevMessageContent} targetLanguage={targetLanguage} sourceLanguage={sourceLanguage} explain={proofread} proofreadModel={proofreadModel} onMessageClick={onMessageClick} isSelected={selectedMsgId === msgId} hidden={hidden}/>
            }
        </div>
    );
};

export default ChatMessage;