import {Button, CircularProgress, Drawer, FormControl, InputLabel, MenuItem, Select, Paper, Typography} from "@mui/material";
import * as PropTypes from "prop-types";
import React from "react";

function SettingsDrawer(props) {
    return <>
        {/* Collapsible Drawer for Chat Settings */}
        <Drawer anchor="right" open={props.open} onClose={props.onClose}>
            <div style={{width: 250, padding: "20px"}}>
                <h3>Chat Settings</h3>
                <FormControl fullWidth variant="outlined" style={{marginBottom: "20px"}}>
                    <InputLabel htmlFor="difficulty">Conversation Level</InputLabel>
                    <Select
                        value={props.difficulty}
                        onChange={props.onDifficultyChange}
                        label="Difficulty Level"
                        id="difficulty"
                    >
                        <MenuItem value="Beginner">Beginner</MenuItem>
                        <MenuItem value="Advanced Beginner">Advanced Beginner</MenuItem>
                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                        <MenuItem value="Advanced">Advanced</MenuItem>
                    </Select>
                </FormControl>
                { Object.keys(props.languages).length !== 0 ? <FormControl fullWidth variant="outlined" style={{marginBottom: "20px"}}>
                    <InputLabel htmlFor="chat-language">Chat Language</InputLabel>
                    <Select
                        value={props.languageName}
                        onChange={(event) => {
                            console.log(`language selection menu reached with event.target.value`, event.target.value)
                            // console.log("props.languages", props.languages)
                            const selectedLangName = event.target.value
                            props.onLanguageChange(selectedLangName)
                            }
                        }
                        label="Chat Language"
                        id="chat-language"
                    >
                        {
                            Object.keys(props.languages).map(langName => (
                            <MenuItem key={langName} value={langName}>{langName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                    : <div><CircularProgress/> Loading Languages...</div>
                }
                {Object.keys(props.languages).length !== 0 && Object.keys(props.languages[props.languageName].regions).length !==0 && <FormControl fullWidth variant="outlined" style={{marginBottom: "20px"}}>
                    <InputLabel htmlFor="chat-language-region">Language Region</InputLabel>
                    <Select
                        value={props.languageRegionCode}
                        onChange={props.onLanguageRegionChange}
                        label="Language Region"
                        id="chat-language-region"
                    >
                        {
                            props.languages && props.languages[props.languageName] && props.languages[props.languageName].regions && Object.keys(props.languages[props.languageName].regions).map(regionName => (
                            <MenuItem key={props.languages[props.languageName].regions[regionName]} value={props.languages[props.languageName].regions[regionName]}>{regionName}</MenuItem>
                            )
                        )
                        }
                    ))
                    </Select>
                </FormControl>
                }

                <FormControl fullWidth variant="outlined" style={{marginTop: "20px"}}>
                    <InputLabel htmlFor="openai-model">AI Model</InputLabel>
                    <Select
                        value={props.openAiModel}
                        onChange={props.onOpenAiModelChange}
                        label="Open AI Model"
                        id="openai-model"
                    >
                        <MenuItem value="gpt-3.5-turbo">Standard Model</MenuItem>
                        <MenuItem value="gpt-4">Advanced Model</MenuItem>
                        {/* Add more languages as needed */}
                    </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined" style={{marginTop: "20px"}}>
                    <InputLabel htmlFor="proofread-model">Proofread Model</InputLabel>
                    <Select
                        value={props.proofreadModel}
                        onChange={props.onProofreadModelChange}
                        label="Proofread Model"
                        id="proofread-model"
                    >
                        <MenuItem value="gpt-3.5-turbo">Standard Model</MenuItem>
                        <MenuItem value="gpt-4">Advanced Model</MenuItem>
                        {/* Add more languages as needed */}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: '20px' }}
                    onClick={props.onProofreadChange}
                >
                    {props.proofread ? 'Disable' : 'Enable'} Proofread Mode
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginTop: '20px' }}
                    onClick={props.onLogout}
                >
                    Logout
                </Button>
                <Paper elevation={1} style={{ padding: '10px', marginTop: '20px' }}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Logged in as
                    </Typography>
                    <Typography variant="h6">
                        {props.user.email}
                    </Typography>
                </Paper>

            </div>
        </Drawer>
    </>;
}

export default SettingsDrawer;